// Import fonts
import './bootstrap';

// Import Font Awesome configuration first
import './fontawesome';

// Import your modules
import { initImageModal } from './early-access/modal';
import earlyAccessForm from './early-access/early-access-form';
import placeData from './places';
import imageSlider from './components/imageSlider';
import imageUploader from './components/imageUploader';
import { initializeCheckIn } from './places/check-in';
import { initAnimations } from './animations';

// Create a namespace for all place-related functionality
window.Places = {
    placeData,
    imageSlider,
    allPlaces: {} // Add this empty object to satisfy any potential references
};

// Only import Alpine if it's not already defined
let Alpine;
if (!window.Alpine) {
    Alpine = (await import('alpinejs')).default;
    window.Alpine = Alpine;
}

// Make components available globally
window.placeData = placeData;
window.imageSlider = imageSlider;

// Initialize animations after Alpine is ready
document.addEventListener('alpine:initialized', () => {
    console.log('Alpine initialized, starting animations...'); // Debug log
    initAnimations();
});

// Wait for Alpine to be available (either from our import or Livewire)
document.addEventListener('alpine:init', () => {
    // Register Alpine components
    window.Alpine.data('earlyAccessForm', earlyAccessForm);
    window.Alpine.data('imageSlider', imageSlider);
    window.Alpine.data('placeData', placeData);
    window.Alpine.data('checkInForm', initializeCheckIn);
    window.Alpine.data('imageUploader', imageUploader);

    // Create stores
    window.Alpine.store('placeData', {
        selectedCategories: [],
        visibleCategories: new Set()
    });

    window.Alpine.store('categoryData', {
        visibleCategories: new Set()
    });
});

// Only start Alpine if we imported it (if it wasn't already available)
if (Alpine) {
    Alpine.start();
}

// Initialize other components
document.addEventListener('DOMContentLoaded', () => {
    initImageModal();
    document.body.classList.add('js-loaded');
});
