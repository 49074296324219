import { library, dom } from '@fortawesome/fontawesome-svg-core';

// Import Pro icons
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';

// Brand icons
import {
    faFacebookF,
    faXTwitter,
    faBluesky,
    faRedditAlien,
    faWhatsapp,
    faTelegram,
    faGoogle,
    faApple,
    faWaze,
    faInstagram,
    faYoutube,
    faTiktok,
    faFacebook,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons';

// Add icons to library
library.add(
    // Pro styles
    fas,  // Solid
    far,  // Regular
    fal,  // Light
    fad,  // Duotone
    fass, // Sharp Solid
    // Brands
    faFacebookF,
    faXTwitter,
    faBluesky,
    faRedditAlien,
    faWhatsapp,
    faTelegram,
    faGoogle,
    faApple,
    faWaze,
    faInstagram,
    faYoutube,
    faTiktok,
    faFacebook,
    faLinkedin
);

// Watch the DOM for changes and replace icons
dom.watch();

// Add class to body when Font Awesome is loaded
document.addEventListener('DOMContentLoaded', () => {
    document.body.classList.add('fontawesome-loaded');
});
