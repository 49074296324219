import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

// Set initial states for all animated elements
function setInitialStates() {
    // Hero section
    gsap.set(['.hero-logo', '.hero-title', '.hero-description', '.hero-cta', '.hero-form'], {
        opacity: 0,
        visibility: 'visible' // Make elements visible for GSAP
    });

    // Platform Features section
    gsap.set('.platform-features-title', {
        opacity: 0,
        y: 30,
        visibility: 'visible'
    });

    gsap.set('.platform-features-description', {
        opacity: 0,
        y: 20,
        visibility: 'visible'
    });

    gsap.set('.feature-card', {
        opacity: 0,
        y: 50,
        visibility: 'visible'
    });

    gsap.set('.feature-icon', {
        scale: 0.5,
        opacity: 0,
        visibility: 'visible'
    });

    gsap.set('.feature-list li', {
        opacity: 0,
        x: -20,
        visibility: 'visible'
    });

    // Features section
    gsap.set('img[alt="Landgogh Van"]', {
        x: '-100vw',
        opacity: 0,
        visibility: 'visible'
    });

    // FAQ section
    gsap.set('.faq-section', {
        opacity: 0,
        visibility: 'visible'
    });

    // Social section
    gsap.set(['.social-title', '.social-link'], {
        opacity: 0,
        visibility: 'visible'
    });
}

// Hero Section Animations
function initHeroAnimations() {
    const tl = gsap.timeline({
        defaults: { ease: 'power3.out' },
        onComplete: () => console.log('Hero animations complete')
    });

    tl.to('.hero-logo', {
        y: 0,
        opacity: 0.9,
        duration: 0.6,
        ease: 'power2.out'
    })
    .to('.hero-title', {
        y: 0,
        opacity: 1,
        duration: 1
    }, '-=0.3')
    .to('.hero-description', {
        y: 0,
        opacity: 1,
        duration: 1
    }, '-=0.7')
    .to('.hero-cta', {
        y: 0,
        opacity: 1,
        duration: 1
    }, '-=0.7')
    .to('.hero-form', {
        x: 0,
        opacity: 1,
        duration: 1
    }, '-=0.7');
}

// Platform Features Section Animations
function initPlatformFeaturesAnimations() {
    // Title and description animation
    const headerTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.platform-features-title',
            start: 'top 80%',
            toggleActions: 'play none none reverse'
        }
    });

    headerTl
        .to('.platform-features-title', {
            opacity: 1,
            y: 0,
            duration: 0.8,
            ease: 'power3.out'
        })
        .to('.platform-features-description', {
            opacity: 1,
            y: 0,
            duration: 0.8,
            ease: 'power3.out'
        }, '-=0.6');

    // Feature cards staggered animation
    const cards = document.querySelectorAll('.feature-card');
    cards.forEach((card, index) => {
        const cardTl = gsap.timeline({
            scrollTrigger: {
                trigger: card,
                start: 'top 85%',
                toggleActions: 'play none none reverse'
            }
        });

        // Animate the card
        cardTl
            .to(card, {
                opacity: 1,
                y: 0,
                duration: 0.8,
                ease: 'power3.out'
            })
            // Animate the icon with a bounce effect
            .to(card.querySelector('.feature-icon'), {
                scale: 1,
                opacity: 1,
                duration: 0.6,
                ease: 'back.out(1.7)'
            }, '-=0.4')
            // Animate list items with stagger
            .to(card.querySelectorAll('.feature-list li'), {
                opacity: 1,
                x: 0,
                duration: 0.4,
                stagger: 0.1,
                ease: 'power2.out'
            }, '-=0.2');
    });

    // Add hover animations
    cards.forEach(card => {
        card.addEventListener('mouseenter', () => {
            gsap.to(card, {
                scale: 1.02,
                duration: 0.3,
                ease: 'power2.out'
            });
            gsap.to(card.querySelector('.feature-icon'), {
                scale: 1.1,
                duration: 0.3,
                ease: 'power2.out'
            });
        });

        card.addEventListener('mouseleave', () => {
            gsap.to(card, {
                scale: 1,
                duration: 0.3,
                ease: 'power2.out'
            });
            gsap.to(card.querySelector('.feature-icon'), {
                scale: 1,
                duration: 0.3,
                ease: 'power2.out'
            });
        });
    });
}

// Features Section Animations
function initFeaturesAnimations() {
    const featuresTl = gsap.timeline({
        scrollTrigger: {
            trigger: 'img[alt="Landgogh Van"]',
            start: 'top 80%',
            toggleActions: 'play none none reverse'
        }
    });

    featuresTl
        .to('img[alt="Landgogh Van"]', {
            x: 0,
            opacity: 0.9,
            duration: 1.2,
            ease: 'power2.inOut'
        })
        .to('img[alt="Landgogh Van"]', {
            x: '+=10',
            duration: 0.1,
            ease: 'power1.out'
        })
        .to('img[alt="Landgogh Van"]', {
            x: '-=10',
            duration: 0.1,
            ease: 'power1.out'
        });

    // Animate feature cards
    const cards = document.querySelectorAll('.pathfinder-card, .adventurer-card, .trailblazer-card');
    cards.forEach((card, index) => {
        gsap.from(card, {
            scrollTrigger: {
                trigger: card,
                start: 'top 80%',
                toggleActions: 'play none none reverse'
            },
            y: 100,
            opacity: 0,
            duration: 1,
            delay: index * 0.3,
            ease: 'power3.out'
        });

        // Add a subtle glow animation to the Trailblazer card
        if (card.classList.contains('trailblazer-card')) {
            gsap.to(card, {
                boxShadow: '0 0 30px rgba(var(--color-secondary-500), 0.2)',
                duration: 2,
                repeat: -1,
                yoyo: true,
                ease: 'sine.inOut'
            });
        }
    });
}

// FAQ Section Animations
function initFaqAnimations() {
    gsap.to('.faq-section', {
        scrollTrigger: {
            trigger: '.faq-section',
            start: 'top 80%',
            toggleActions: 'play none none none'
        },
        opacity: 1,
        duration: 1,
        ease: 'power2.out'
    });
}

// Social Media Section Animations
function initSocialAnimations() {
    const socialTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.social-title',
            start: 'top 90%',
            toggleActions: 'play none none none'
        }
    });

    socialTl
        .to('.social-title', {
            y: 0,
            opacity: 1,
            duration: 0.8,
            ease: 'power2.out'
        })
        .to('.social-link', {
            scale: 1,
            opacity: 1,
            duration: 0.6,
            stagger: 0.1,
            ease: 'back.out(1.7)'
        }, '-=0.4');
}

// Initialize all animations
export function initAnimations() {
    // Set initial states before any animations begin
    setInitialStates();

    // Start animations after a short delay to ensure DOM is ready
    requestAnimationFrame(() => {
        initHeroAnimations();
        initPlatformFeaturesAnimations();
        initFeaturesAnimations();
        initFaqAnimations();
        initSocialAnimations();
    });
}
