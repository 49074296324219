export default function imageUploader() {
    return {
        dragging: false,
        files: [],
        errors: [],
        maxFileSize: 10 * 1024 * 1024, // 10MB
        allowedTypes: [
            'image/jpeg',    // .jpg, .jpeg
            'image/png',     // .png
            'image/gif',     // .gif
            'image/webp',    // .webp
            'image/avif',    // .avif
            'image/heic',    // .heic
            'image/heif'     // .heif
        ],
        maxFiles: 5,

        init() {
            this.$watch('files', (files) => {
                // Dispatch event to update the file input
                window.dispatchEvent(new CustomEvent('files-updated', {
                    detail: { files }
                }));
            });
        },

        handleFiles(event) {
            const newFiles = Array.from(event.target.files || []);
            this.validateAndAddFiles(newFiles);
        },

        handleDrop(event) {
            this.dragging = false;
            const newFiles = Array.from(event.dataTransfer.files || []);
            this.validateAndAddFiles(newFiles);
        },

        validateAndAddFiles(newFiles) {
            this.errors = [];

            // Check if adding these files would exceed the maximum
            if (this.files.length + newFiles.length > this.maxFiles) {
                this.errors.push(`You can only upload up to ${this.maxFiles} images`);
                return;
            }

            newFiles.forEach(file => {
                // Validate file type
                if (!this.allowedTypes.includes(file.type)) {
                    this.errors.push(`${file.name} is not a supported image type`);
                    return;
                }

                // Validate file size
                if (file.size > this.maxFileSize) {
                    this.errors.push(`${file.name} is too large (max ${this.formatFileSize(this.maxFileSize)})`);
                    return;
                }

                // Create preview URL
                const preview = URL.createObjectURL(file);
                this.files.push({
                    file,
                    name: file.name,
                    size: file.size,
                    preview
                });
            });

            // Reset the input to allow selecting the same file again
            if (event.target) {
                event.target.value = '';
            }
        },

        removeFile(index) {
            // Revoke the object URL to prevent memory leaks
            URL.revokeObjectURL(this.files[index].preview);
            this.files.splice(index, 1);
        },

        formatFileSize(bytes) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const sizes = ['Bytes', 'KB', 'MB', 'GB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        }
    };
}
