export function initializeCheckIn() {
    return {
        newComment: '',
        characterCount: 0,
        isSubmitting: false,

        async submitCheckIn() {
            if (this.characterCount < 25 || this.characterCount > 500 || this.isSubmitting) {
                console.log('Validation failed:', {
                    characterCount: this.characterCount,
                    isSubmitting: this.isSubmitting
                });
                return;
            }

            try {
                this.isSubmitting = true;
                console.log('Starting check-in submission...');

                // Get the selected place from the parent scope
                const modalElement = this.$el.closest('[x-show="showModal"]');
                if (!modalElement) {
                    throw new Error('Modal element not found');
                }

                const modalScope = Alpine.evaluate(modalElement, 'selectedPlace');
                console.log('Selected place:', modalScope);

                if (!modalScope || !modalScope.id) {
                    throw new Error('No place selected');
                }

                // Get CSRF token
                const token = document.querySelector('meta[name="csrf-token"]')?.content;
                if (!token) {
                    throw new Error('CSRF token not found');
                }
                console.log('CSRF Token found');

                // Get the image uploader component
                const imageUploaderEl = this.$el.querySelector('[x-data="imageUploader()"]');
                if (!imageUploaderEl) {
                    throw new Error('Image uploader component not found');
                }
                const imageUploader = Alpine.$data(imageUploaderEl);

                // Create FormData and append the comment
                const formData = new FormData();
                formData.append('comment', this.newComment);

                // Append images if any
                if (imageUploader.files.length > 0) {
                    imageUploader.files.forEach((fileData, index) => {
                        formData.append(`images[]`, fileData.file);
                    });
                }

                console.log('Sending request to:', `/places/${modalScope.id}/check-ins`);
                console.log('Request body:', {
                    comment: this.newComment,
                    imageCount: imageUploader.files.length
                });

                const response = await fetch(`/places/${modalScope.id}/check-ins`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-TOKEN': token
                    },
                    body: formData
                });

                console.log('Response received:', {
                    status: response.status,
                    statusText: response.statusText,
                    headers: Object.fromEntries([...response.headers])
                });

                const responseText = await response.text();
                console.log('Raw response:', responseText);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                let jsonResult;
                try {
                    jsonResult = JSON.parse(responseText);
                    console.log('Parsed response:', jsonResult);
                } catch (e) {
                    console.error('Failed to parse JSON response:', e);
                    throw new Error('Invalid response format from server');
                }

                // Clear the form
                this.newComment = '';
                this.characterCount = 0;
                imageUploader.files.forEach((file, index) => {
                    URL.revokeObjectURL(file.preview);
                });
                imageUploader.files = [];
                this.isSubmitting = false;

                // Fetch updated check-ins
                const checkInsResponse = await fetch(`/places/${modalScope.id}/check-ins`);
                if (!checkInsResponse.ok) {
                    throw new Error(`HTTP error! status: ${checkInsResponse.status}`);
                }
                const checkIns = await checkInsResponse.json();
                console.log('Fetched updated check-ins:', checkIns);
                modalScope.check_ins = checkIns;
            } catch (error) {
                console.error('Error submitting check-in:', {
                    message: error.message,
                    stack: error.stack
                });
                alert(`Failed to submit check-in: ${error.message}`);
                this.isSubmitting = false;
            }
        }
    };
}
