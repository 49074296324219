export default function placeData() {
    return {
        showModal: false,
        selectedPlace: null,
        newComment: '',
        characterCount: 0,
        isSubmitting: false,
        comments: [],
        clickedPlaceIndex: null,
        showCategoryModal: false,
        visibleCategoryCount: 10,
        selectedCategories: [],
        viewMode: window.innerWidth >= 768 ? 'both' : 'list',
        uniqueCategories: [],
        categoryDisplayInfo: {},
        categorySearch: '',
        isMapInitialized: false,

        init() {
            this.initializeCategories();
            this.handleResize();
            window.addEventListener('resize', () => this.handleResize());

            // Delay map initialization until after initial render
            requestAnimationFrame(() => {
                if (window.map && !this.isMapInitialized) {
                    this.initializeMap();
                }
            });
        },

        initializeCategories() {
            if (!window.categoriesData) return;

            this.uniqueCategories = window.categoriesData.map(cat => cat.name);
            this.categoryDisplayInfo = window.categoriesData.reduce((acc, cat) => {
                acc[cat.name] = {
                    image: cat.image,
                    placeCount: cat.placeCount,
                    checkInsCount: cat.checkInsCount
                };
                return acc;
            }, {});
        },

        getCategoryImage(categoryName) {
            return this.categoryDisplayInfo[categoryName]?.image;
        },

        getCategoryDisplayInfo(categoryName) {
            return this.categoryDisplayInfo[categoryName] || { placeCount: 0, checkInsCount: 0 };
        },

        toggleCategory(category) {
            const index = this.selectedCategories.indexOf(category);
            if (index === -1) {
                this.selectedCategories.push(category);
            } else {
                this.selectedCategories.splice(index, 1);
            }
            this.updateMapMarkers();
        },

        clearCategories() {
            this.selectedCategories = [];
            this.updateMapMarkers();
        },

        isPlaceVisible(index) {
            if (!window.placesData || !window.placesData[index]) return false;
            const place = window.placesData[index];

            // If no categories are selected, show all places
            if (this.selectedCategories.length === 0) return true;

            // Check if the place's category is in the selected categories
            return this.selectedCategories.includes(place.category?.name);
        },

        updateMapMarkers() {
            if (!window.markerClusterGroup) return;

            window.markers.forEach(({ marker, place }, index) => {
                if (this.selectedCategories.length === 0 || this.selectedCategories.includes(place.category?.name)) {
                    window.markerClusterGroup.addLayer(marker);
                } else {
                    window.markerClusterGroup.removeLayer(marker);
                }
            });
        },

        get filteredCategories() {
            if (!this.categorySearch) return this.uniqueCategories;
            const search = this.categorySearch.toLowerCase();
            return this.uniqueCategories.filter(cat =>
                cat.toLowerCase().includes(search)
            );
        },

        handleResize() {
            if (window.innerWidth >= 768) {
                this.viewMode = 'both';
            } else if (!this.viewMode || this.viewMode === 'both') {
                this.viewMode = 'list';
            }
        },

        toggleHighlightPlace(index) {
            if (window.markers && window.markers[index]) {
                const { marker } = window.markers[index];
                const icon = marker.getIcon();
                const originalSize = icon.options.iconSize;
                const enlargedSize = [originalSize[0] * 1.2, originalSize[1] * 1.2];

                const enlargedIcon = L.icon({
                    iconUrl: icon.options.iconUrl,
                    iconSize: enlargedSize,
                    iconAnchor: [enlargedSize[0] / 2, enlargedSize[1]],
                    popupAnchor: [0, -enlargedSize[1]]
                });

                marker.setIcon(enlargedIcon);
                marker.setZIndexOffset(1000);

                // Store the original icon for later
                marker.originalIcon = icon;
            }
        },

        clearHighlight() {
            if (window.markers) {
                window.markers.forEach(({ marker }) => {
                    if (marker.originalIcon) {
                        marker.setIcon(marker.originalIcon);
                        marker.setZIndexOffset(0);
                        marker.originalIcon = null;
                    }
                });
            }
        },

        async showModalAndFetchComments(place) {
            if (!place) return;

            // Dispatch to Livewire component with proper Livewire 3 format
            Livewire.dispatch('show-place', [place.id]);

            // Find and store the index of the clicked place
            this.clickedPlaceIndex = window.placesData.findIndex(p => p.id === place.id);

            // Highlight the clicked place
            if (this.clickedPlaceIndex !== -1) {
                this.toggleHighlightPlace(this.clickedPlaceIndex);
            }
        },

        fetchComments() {
            if (!this.selectedPlace?.id) return;

            fetch(`/places/${this.selectedPlace.id}/comments`)
                .then(response => response.json())
                .then(data => {
                    this.comments = data;
                })
                .catch(error => {
                    console.error('Error fetching comments:', error);
                });
        },

        // ... rest of the existing code ...
    };
}
